<template>
  <div>
    <!-- 大屏首页 -->
    <section class="section p0 panel">
      <div class="col-lg-12 p0 container_max">
        <video
          autoplay
          muted
          ref="video_top"
          playsInline="{true}"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          @ended="onPlayerEnd($event)"
          style="width: 100%; height: 100%; object-fit: cover"
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/BLACKWAVE_2X.mp4"
        ></video>
        <div class="center_box flex-cc">
          <p class="T64px_Regular colorfff max_title wow animate__fadeInUp">
            {{ $t("home.headerTitle") }}
          </p>
        </div>
      </div>
    </section>

    <section ref="carouse_section" class="section p0 panel poject_box">
      <el-carousel
        ref="carouse"
        :autoplay="false"
        height="100vh"
        indicator-position="none"
        :initial-index="initialIndex"
      >
        <el-carousel-item v-for="(item, index) in projectList" :key="index">
          <div
            class="col-lg-12 p0 project_img_box flex-w"
            :class="projectVideoState ? 'act' : ''"
          >
            <img
              class="project_img"
              v-show="!projectVideoState"
              :src="item.img"
              alt=""
            />
            <video
              autoplay
              loop
              muted
              v-show="projectVideoState"
              playsInline="{true}"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              x5-playsinline="true"
              x5-video-orientation="portraint"
              style="width: 100%; height: 100%; object-fit: cover"
              :src="item.video"
            ></video>
            <div class="flex-c project_text_box">
              <img
                src="@/assets/images/home/ux.png"
                data-wow-duration="1.5s"
                class="ux_icon wow animate__fadeInUp"
                alt=""
              />
              <h1
                data-wow-duration="1.5s"
                class="T36px_Regular colorfff title_name wow animate__fadeInUp"
              >
                {{ item.title }}
              </h1>

              <span
                data-wow-duration="2s"
                class="T16px_Regular colorfff tips_text wow animate__fadeInUp"
              >
                {{ item.tips }}
              </span>
              <button
                @mouseover="infoMouseover"
                @mouseleave="infoMouseleave"
                @click="routerBtn(item.routerUrl)"
                class="info_project_btn T12px_Regular colorfff animation_right"
              >
                {{ $t("home.projectListInfo") }}
              </button>

              <div
                class="arr_left_box flex-cc animation_right"
                @click="initialIndexChange(item.runIndex)"
              >
                <img
                  class="arr_left_icon"
                  src="@/assets/images/home/arr_left.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>

    <section ref="BYTEBRIDGE_box" class="section BYTEBRIDGE_box flex-cc">
      <div class="col-lg-12 flex-cc container">
        <img
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/min_text_logo.png"
          alt=""
        />
        <span
          ref="scalingText"
          class="title color000 BYTEBRIDGE_title T20px_Regular"
        >
          {{ $t("home.scalingText") }}
        </span>
        <span class="xian"></span>
      </div>
    </section>

    <!-- 流程 -->
    <section ref="refFlow" class="section flow_box flex-cc">
      <div class="container flex-w">
        <div class="col-lg-3 left_title_box">
          <h1 class="T48px_Regular left_title panel-text act">
            <span class="act_text">{{ $t("home.flowTitle1_a") }}</span>
            <span>{{ $t("home.flowTitle1_b") }}</span>
          </h1>
          <h1 class="T48px_Regular left_title panel-text">
            <span class="act_text">{{ $t("home.flowTitle2_a") }}</span>
            <span>{{ $t("home.flowTitle2_b") }}</span>
          </h1>
          <h1 class="T48px_Regular left_title panel-text">
            <span class="act_text">{{ $t("home.flowTitle3_a") }}</span>
            <span>{{ $t("home.flowTitle3_b") }}</span>
          </h1>
          <h1 class="T48px_Regular left_title panel-text purple">
            <span class="act_text">{{ $t("home.flowTitle4_a") }}</span>
            <span>{{ $t("home.flowTitle4_b") }}</span>
          </h1>
        </div>

        <div class="col-lg-8">
          <div class="photo_frame_box">
            <div
              class="photo_frame_list flex-cc panelFlow"
              :class="index == 3 ? 'purple' : ''"
              v-for="(item, index) in photoFrameList"
              :key="index"
            >
              <img :src="item.url" class="photo_frame_img" alt="" />
              <div class="son_box flex-wb">
                <div
                  class="son_item flex-cc"
                  v-for="(items, ins) in item.chile"
                  :key="ins"
                >
                  <img :src="items.url" alt="" />
                  <span class="T12px_Regular colorfff">{{ items.name }}</span>
                </div>
              </div>

              <div class="photo_frame_text T16px_Regular colorfff">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-2 h100">
          <div class="right_box flex-c">
            <div
              class="T14px_Regular colorfff right_text wow animate__fadeInRight"
            >
              <span v-html="$t('home.right_text')"></span>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <!-- 步骤  -->
    <!-- 博客 -->
    <section class="section blog_box">
      <div class="container flex-wbt">
        <!-- <div
          class="col-lg-2 col-2 h100 T14px_Regular left_tips_box colorfff wow animate__fadeInLeft"
        >
          <span v-html="$t('home.blog_left_tips')"></span>
        </div> -->
        <div class="col-lg-11 col-11 flex-wr">
          <h1
            class="T48px_Regular colorfff col-10 flex-wr wow animate__fadeInUp"
          >
            <span class="color1D73FF">{{ $t("home.blog_title1") }}</span>
            <span>{{ $t("home.blog_title2") }}</span>
          </h1>

          <div class="new_bolg_box col-12">
            <div
              class="new_bolg_item flex-wb"
              v-for="(item, index) in list"
              @click="routerBtn(item.routerUrl)"
              :key="index"
            >
              <img class="bolg_img" :src="item.img" alt="" />
              <div class="flex-ce">
                <span class="T12px_Regular colorC8C8C8">{{ item.time }}</span>
                <span class="colorfff T24px_Regular_ya title">{{
                  item.title
                }}</span>
                <div class="T14px_Regular colorfff yuedu_btn">
                  <span>{{ $t("home.gengduo") }}</span>
                  <img
                    class="info_icon"
                    src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/info.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            @click="routerBtn('/blolist')"
            class="gengduo_box T16px_Regular colorfff flex=wr"
          >
            <span>{{ $t("home.gengduos") }}</span>
            <img
              class="gengduo_icon"
              src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/gengduo.png"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-12 customized_box flex-wb">
          <div class="flex-c">
            <span class="T36px_Regular colorfff">
              {{ $t("customized.title") }}
              <span class="color1D73FF">{{ $t("customized.title1") }}</span>
            </span>
            <span class="T16px_Regular color818181">
              {{ $t("customized.tipe") }}
            </span>
          </div>
          <div class="flex-c">
            <span class="T14px_Regular colorfff tips_net">
              {{ $t("customized.tipe_ri") }}
            </span>
            <span
              @click="routerBtn('/contact')"
              class="T20px_Regular colorfff my_btn animation_right"
            >
              {{ $t("customized.btn") }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";
export default {
  data() {
    return {
      pages: 100,
      currentPage: 5,
      projectVideoState: false,
      projectList: [
        {
          runIndex: 1,
          title: this.$t("home.projectListInfoTitle1"),
          tips: this.$t("home.projectListInfoTips1"),
          img: require("@/assets/images/home/one_bj.png"),
          routerUrl: "/moXun",
          video:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/moxun.mp4",
        },
        {
          runIndex: 2,
          title: this.$t("home.projectListInfoTitle2"),
          tips: this.$t("home.projectListInfoTips2"),
          img: require("@/assets/images/home/one_bj1.png"),
          routerUrl: "/ninja",
          video:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/renz.mp4",
        },
        {
          runIndex: 0,
          title: this.$t("home.projectListInfoTitle3"),
          tips: this.$t("home.projectListInfoTips3"),
          img: require("@/assets/images/home/one_bj2.png"),
          routerUrl: "/sanhaiJing",
          video:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/sea/Top.mp4",
        },
      ],
      photoFrameList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/gif1.gif",
          text: this.$t("home.flowTitle1_text"),
          chile: [
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f1_son1.png",
              name: this.$t("home.flowTitle1_chile1"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f1_son2.png",
              name: this.$t("home.flowTitle1_chile2"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f1_son3.png",
              name: this.$t("home.flowTitle1_chile3"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f1_son4.png",
              name: this.$t("home.flowTitle1_chile4"),
            },
          ],
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/gif2.gif",
          text: this.$t("home.flowTitle2_text"),
          chile: [
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f2_son1.png",
              name: this.$t("home.flowTitle2_chile1"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f2_son2.png",
              name: this.$t("home.flowTitle2_chile2"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f2_son3.png",
              name: this.$t("home.flowTitle2_chile3"),
            },
          ],
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/gif3.gif",
          text: this.$t("home.flowTitle3_text"),
          chile: [
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f3_son1.png",
              name: this.$t("home.flowTitle3_chile1"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f3_son2.png",
              name: this.$t("home.flowTitle3_chile2"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f3_son3.png",
              name: this.$t("home.flowTitle3_chile3"),
            },
          ],
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/gif4.gif",
          text: this.$t("home.flowTitle4_text"),
          chile: [
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f4_son1.png",
              name: this.$t("home.flowTitle4_chile1"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f4_son2.png",
              name: this.$t("home.flowTitle4_chile2"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f4_son3.png",
              name: this.$t("home.flowTitle4_chile3"),
            },
            {
              url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/f4_son4.png",
              name: this.$t("home.flowTitle4_chile4"),
            },
          ],
        },
      ],
      list: [
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol1_img.png",
          time: this.$t("home.blog_list1_time"),
          title: this.$t("home.blog_list1_title"),
          routerUrl: "/blobrand",
        },
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol2_img.png",
          time: this.$t("home.blog_list2_time"),
          title: this.$t("home.blog_list2_title"),
          routerUrl: "/blotob",
        },
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol3_img.png",
          time: this.$t("home.blog_list3_time"),
          title: this.$t("home.blog_list3_title"),
          routerUrl: "/bloaddtob",
        },
      ],
      initialIndex: 0,

      isAtTop: true,
    };
  },
  mounted() {
    this.slideInit();
    this.flowSlideInit();
    const wow = new WOW();
    wow.init();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * 滑动监听
     */
    handleScroll() {
      // 判断页面是否滚动到最顶部
      if (window.scrollY === 0 && !this.isAtTop) {
        this.isAtTop = true;
        this.$refs.video_top.currentTime = 0;
        this.$refs.video_top.play();
      }
    },
    /**
     * 视频播放完方法
     */
    onPlayerEnd() {
      // 获取目标元素
      const targetElement = this.$refs.carouse_section;

      // 判断目标元素的位置是否已经在视图中
      const elementTop = targetElement.offsetTop;
      const elementBottom = elementTop + targetElement.clientHeight;
      const currentScrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      this.isAtTop = false;
      // 判断是否已经浏览过
      if (
        currentScrollY + viewportHeight > elementBottom || // 完全在视图中
        currentScrollY > elementTop // 已经过了目标元素的位置
      ) {
        console.log("目标部分已浏览，不需要滑动");
      } else {
        // 如果没有浏览过，则平滑滚动到该部分
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    /**
     * 控制轮播图索引
     */
    initialIndexChange(runIndex) {
      this.$refs.carouse.setActiveItem(runIndex);
    },
    /**
     * 步骤滚动动画
     */
    stopSlideInit() {
      gsap.set(".refStop", {
        zIndex: (i, target, targets) => targets.length - i,
      });
      var texts = gsap.utils.toArray(".panel_stop_box");

      texts.forEach((text, i) => {
        var tl = gsap.timeline({
          scrollTrigger: {
            trigger: "section.step_box",
            scroller: this.$refs.scroller,
            start: () => "top -" + window.innerHeight * 0.2 * i,
            end: () => "+=" + window.innerHeight * 0.2,
            scrub: true, // 设置为 true 来增强平滑度
            toggleActions: "play none reverse none",
            // invalidateOnRefresh: false,
            onEnter: () => {
              gsap.to(text, {
                duration: 0.5,
                ease: "power2.out",
                onComplete: () => text.classList.add("act"),
              });
            },
            onLeave: () => {
              if (i === texts.length - 1) {
                gsap.to(text, {
                  duration: 0.5,
                  ease: "power2.in",
                  onComplete: () => text.classList.add("act"),
                });
              } else {
                gsap.to(text, {
                  duration: 0.5,
                  ease: "power2.in",
                  onComplete: () => text.classList.remove("act"),
                });
              }
            },
            onEnterBack: () => {
              gsap.to(text, {
                duration: 0.5,
                ease: "power2.out",
                onComplete: () => text.classList.add("act"),
              });
            },
            onLeaveBack: () => {
              if (i === 0) {
                gsap.to(text, {
                  duration: 0.5,
                  ease: "power2.in",
                  onComplete: () => text.classList.add("act"),
                });
              } else {
                gsap.to(text, {
                  duration: 0.5,
                  ease: "power2.in",
                  onComplete: () => text.classList.remove("act"),
                });
              }
            },
          },
        });

        tl.to(text, { duration: 1, ease: "power2.inOut" }).to(
          text,
          { duration: 1 },
          0.66
        );
      });
      texts[0].classList.add("act");
      ScrollTrigger.create({
        trigger: "section.step_box",
        scroller: this.$refs.scroller,
        scrub: true,
        markers: false,
        pin: true,
        start: "top top",
        end: () => "+=" + (texts.length + 1) * window.innerHeight * 0.2,
        invalidateOnRefresh: false,
        onLeaveBack: () => {
          texts[0].classList.add("act"); // 确保在回到顶部时，第一个元素始终激活
        },
      });
    },
    /**
     * 流程滚动动画
     */
    flowSlideInit() {
      gsap.set(".panelFlow", {
        zIndex: (i, target, targets) => targets.length - i,
      });

      var images = gsap.utils.toArray(".panelFlow");
      var texts = gsap.utils.toArray(".panel-text");

      images.forEach((image, i) => {
        var tl = gsap.timeline({
          scrollTrigger: {
            trigger: "section.flow_box",
            scroller: this.$refs.scroller,
            start: () => "top -" + window.innerHeight * 0.5 * (i + 0.5),
            end: () => "+=" + window.innerHeight * 0.5,
            scrub: true, // 使动画更平滑
            toggleActions: "play none reverse none",
            invalidateOnRefresh: false,
            onEnter: () => {
              gsap.to(image, {
                height: "28.75rem",
                duration: 0.5,
                ease: "power2.out",
              }); // 显示当前的 image
              texts.forEach((text, j) => {
                if (i === j) {
                  text.classList.add("act"); // 激活对应的 text
                } else {
                  text.classList.remove("act"); // 移除其他 text 的激活状态
                }
              });
            },
            onLeave: () => {
              if (i === texts.length - 1) {
                gsap.to(image, {
                  height: "28.75rem",
                  duration: 0.5,
                  ease: "power2.out",
                }); // 显示当前的 image
                texts.forEach((text, j) => {
                  if (i === j) {
                    text.classList.add("act"); // 激活对应的 text
                  } else {
                    text.classList.remove("act"); // 移除其他 text 的激活状态
                  }
                });
              } else {
                gsap.to(image, { height: 0, duration: 0.5, ease: "power2.in" }); // 隐藏当前的 image
              }
            },
            onEnterBack: () => {
              gsap.to(image, {
                height: "28.75rem",
                duration: 0.5,
                ease: "power2.out",
              }); // 显示当前的 image
              texts.forEach((text, j) => {
                if (i === j) {
                  text.classList.add("act"); // 激活对应的 text
                } else {
                  text.classList.remove("act"); // 移除其他 text 的激活状态
                }
              });
            },
            onLeaveBack: () => {
              if (i === 0) {
                gsap.to(image, {
                  height: "28.75rem",
                  duration: 0.5,
                  ease: "power2.out",
                }); // 显示当前的 image
                texts.forEach((text, j) => {
                  if (i === j) {
                    text.classList.add("act"); // 激活对应的 text
                  } else {
                    text.classList.remove("act"); // 移除其他 text 的激活状态
                  }
                });
              } else {
                gsap.to(image, { height: 0, duration: 0.5, ease: "power2.in" }); // 隐藏当前的 image
              }
            },
          },
        });
      });

      ScrollTrigger.create({
        trigger: "section.flow_box",
        scroller: this.$refs.scroller,
        scrub: true,
        markers: false,
        pin: true,
        start: "top top",
        end: () => "+=" + (images.length + 1) * window.innerHeight * 0.5,
        invalidateOnRefresh: false,
        onLeaveBack: () => {
          texts[0].classList.add("act"); // 确保在回到顶部时，第一个元素始终激活
        },
      });
    },
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      let tops = panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /***
     * 项目按钮鼠标进入事件
     */
    infoMouseover() {
      this.projectVideoState = true;
    },
    /***
     * 项目按钮鼠标离开事件
     */
    infoMouseleave() {
      this.projectVideoState = false;
    },
    /**
     * 字体根据页面滚动显示当前位置缩放大小
     */
    initScrollAnimation() {
      // 获取元素引用
      const scalingText = this.$refs.scalingText;
      const BYTEBRIDGE_box = this.$refs.BYTEBRIDGE_box;
      const windowHeight = window.innerHeight;

      // 初始化ScrollMagic控制器
      const controller = new ScrollMagic.Controller();

      // 定义GSAP动画
      const tween = gsap.fromTo(
        scalingText,
        { scale: 1 },
        { scale: 1.5, ease: "power0.easeIn", duration: 0.5 }
      );
      console.log(
        BYTEBRIDGE_box.getBoundingClientRect().top - windowHeight * 0.3
      );
      // 创建ScrollMagic场景
      new ScrollMagic.Scene({
        offset: BYTEBRIDGE_box.getBoundingClientRect().top - windowHeight,
        duration: windowHeight * 0.5,
      })
        .setTween(tween)
        .addTo(controller); // 添加到控制器
    },

    linkGen(pageNum) {
      this.currentPage = pageNum;
    },
    infoRouter() {
      this.$router.push("houseInfo");
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.container_max {
  position: relative;
  height: 100vh;

  .center_box {
    position: absolute;
    // top: 0;
    bottom: 6.3438rem;
    left: 0;
    right: 0;
    margin: auto;
    // margin-top: 6.25rem;

    .max_title {
      margin-top: 1.9375rem;
      letter-spacing: 0.375rem;
    }
  }
}

.poject_box {
  min-height: 100vh;
}

.project_img_box {
  // height: 30.0938rem;
  height: 100vh;
  min-height: 100vh;
  position: relative;

  &.act:after {
    opacity: 0;
    transition: all 0.5s ease;
  }

  &:after {
    position: absolute;
    display: inline-block;
    content: " ";
    background: rgba(21, 23, 29, 0.87);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .project_img {
    width: 100%;
    height: 100%;
  }

  .project_text_box {
    position: absolute;
    width: 13.1875rem;
    right: 5.875rem;
    z-index: 3;
    text-align: right;
    align-items: flex-end;

    .ux_icon {
      width: 2.1875rem;
      height: 0.5625rem;
      object-fit: contain;
    }

    .title_name {
      height: 1.5rem;
      line-height: 1.5313rem;
    }

    .tips_text {
      margin-top: 0.7813rem;
      line-height: 1.3125rem;
      // letter-spacing: 0.0313rem;
      margin-bottom: 2.1875rem;
      font-weight: 300 !important;
      // font-style: normal;
      text-align: justify;
    }

    .info_project_btn {
      width: 3.3125rem;
      height: 1.3438rem;
      border: 0.0313rem solid #ffffff;
      background: transparent;
    }

    .arr_left_box {
      margin-top: 5.4688rem;
      width: 3.3438rem;
      height: 3.3438rem;
      text-align: center;
      border-radius: 50%;
      border: 0.0313rem solid #ffffff;
      overflow: hidden;

      .arr_left_icon {
        width: 0.4688rem;
        height: 0.8125rem;
      }
    }
  }
}

.BYTEBRIDGE_box {
  height: 10.875rem;
  background: #fff;
  padding: 0;

  .BYTEBRIDGE_title {
    width: 25.75rem;
    text-align: center;
    margin-top: 0.9375rem;
    transition: all 0.5s ease;
  }

  .xian {
    width: 2.6563rem;
    height: 0rem;
    border: 0.0313rem solid #a9a9a9;
    margin: 0.9375rem 0 0.9375rem 0;
  }
}

.flow_box {
  min-height: 100vh;
  height: 30.6875rem;
  padding: 0;
  background: #15171d;

  // align-items: flex-start;
  // justify-content: flex-start;
  .container {
    position: sticky;
  }

  .left_title_box {
    .left_title {
      font-size: 1.25rem;
      height: 2.0313rem;
      line-height: 2.0313rem;
      color: #323131;
      transition: all 0.2s;
    }

    .act {
      font-size: 1.5rem;
      color: #fff;
      transition: all 0.2s;

      & .act_text {
        color: #1d73ff;
      }
    }
  }

  .photo_frame_box {
    height: 28.75rem;
    width: 24.4688rem;
    overflow: hidden;
    margin-left: 3.125rem;
    position: relative;
    background: #15171d;

    .photo_frame_list {
      position: absolute;
      height: 28.75rem;
      width: 24.4688rem;
      // height: 0;
      background: #15171d;
      background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/photo_frame_bj.png");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
      flex-wrap: nowrap;
    }

    .photo_frame_img {
      width: 10.9375rem;
      height: 10.9375rem;
    }

    .son_box {
      width: 12.2813rem;
      min-height: 2.2188rem;
      margin-top: 1.625rem;

      .son_item {
        height: 100%;
        justify-content: space-between;
        img {
          width: 2rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .photo_frame_text {
      margin-top: 0.875rem;
      width: 18.0313rem;
      text-align: center;
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
    }
  }

  .right_box {
    width: 100%;
    height: 100%;
    align-items: flex-end;

    .right_text {
      line-height: 1.3125rem;
      margin-top: 0.9375rem;
    }

    .arr_left_box {
      margin-top: 13.0938rem;
      width: 3.3438rem;
      height: 3.3438rem;
      text-align: center;
      border-radius: 50%;
      border: 0.0313rem solid #ffffff;

      .arr_left_icon {
        width: 0.4688rem;
        height: 0.8125rem;
      }
    }
  }
}

.step_box {
  padding: 0;
  background: #15171d;
  height: 30.6875rem;

  .step_son_item {
    // min-width: 4.5438rem;
    width: calc((100vw - 31.063rem) / 6);
    position: relative;
    min-height: 24.5313rem;
    top: 0;

    .ranking {
      position: absolute;
      color: #fff;
      top: 14.1563rem;
      right: 0.75rem;
    }

    .step_son_max_logo {
      width: 4.8438rem;
      height: 16.4375rem;
      margin-bottom: 1.375rem;
    }

    .step_son_item_title {
      border-bottom: 0.0313rem solid;
      padding-bottom: 0.6563rem;
      border-image: linear-gradient(
          90deg,
          rgba(55, 55, 55, 1),
          rgba(71, 71, 71, 0.63),
          rgba(51, 51, 51, 0.4)
        )
        1 1;

      .step_son_item_title_text {
        display: none;
      }
    }

    .step_son_text {
      width: 3.3125rem;
      height: 3.9375rem;
      overflow: hidden;
      line-height: 1.3125rem;
      color: #404146;
      margin-top: 0.4688rem;
    }

    &::after {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      content: " ";
      width: 4.9063rem;
      height: 16.4375rem;
      background: rgba(0, 0, 0, 0.71);
      z-index: 2;
    }
  }

  .act {
    width: 30.4063rem;
    color: #1d73ff;
    position: relative;
    min-height: 24.5313rem;
    top: 0;

    .ranking {
      display: none;
    }

    .step_son_max_logo {
      width: 30.4063rem;
      height: 16.4375rem;
    }

    .step_son_item_title {
      border-bottom: none;
      margin-left: 5.9375rem;

      .step_son_item_title_text {
        margin-left: 1.0625rem;
        display: block;
      }
    }

    .step_son_text {
      margin-left: 5.9375rem;
      width: 19.9375rem;
      min-height: 3.9375rem;
      color: #ffffff;
    }

    &::after {
      background: transparent;
    }
  }

  @keyframes growRight {
    from {
      width: 4.8438rem;
    }

    to {
      width: 30.4063rem;
    }
  }

  .arr_left_box {
    margin-left: auto;
    margin-right: 5.9375rem;
    margin-top: 1.7813rem;
    width: 1.8438rem;
    height: 1.8438rem;
    border: 0.0313rem solid #ffffff;
    border-radius: 50%;

    .arr_left_icon {
      width: 0.25rem;
      height: 0.4375rem;
    }
  }
}

.blog_box {
  padding: 4rem 0 5.5rem 0;
  background: #15171d;

  .left_tips_box {
    height: 100%;
    line-height: 1.3125rem;
  }

  .new_bolg_box {
    width: 32.3125rem;
    margin-top: 1.25rem;
    border-top: 0.0313rem solid #999999;

    .new_bolg_item {
      padding: 1.5625rem 0;
      border-bottom: 0.0313rem solid #999999;

      .bolg_img {
        width: 13.7813rem;
        height: 9.0313rem;
      }

      .title {
        margin-top: 0.4375rem;
        width: 15.625rem;
        text-align: justify;
      }

      .yuedu_btn {
        margin-top: 5.8438rem;

        .info_icon {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .gengduo_box {
    width: 100%;
    margin-top: 0.8438rem;
    text-align: right;

    .gengduo_icon {
      width: 0.4688rem;
      margin-left: 0.125rem;
    }
  }

  .customized_box {
    margin-top: 3.125rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }
}
</style>
